<template>
  <div>
    <div class="box">
      <div class="row">
        <div class="col">
          <table class="table table-sm table-borderless">
            <thead>
            <tr>
              <th>User (Login) Email</th>
              <th>Auth0</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <SlotCopy :valueToCopy="user.auth0Email">
                    {{ user.auth0Email }}
                  </SlotCopy>
                </td>
                <td v-if="hasAuth0Mapping" id="auth0-url"><a :href="auth0url" target="_blank">Auth0</a></td>
                <!-- Force sync functionality doesn't make sense in a multi-user-per-account world - disabled until a multi-user aware replacement is created -->
                <td v-else><button class="btn btn-secondary btn-sm disabled">Force Sync Disabled</button></td>
                <td>
                  <div>
                    <div v-if="socialLoginInfo" class="socialLoginIndicator">
                      <img :alt="socialLoginInfo.text" :src="socialLoginInfo.img" />
                      <span>Logged in with {{socialLoginInfo.text}}</span>
                    </div>
                    <div v-else id="password-reset-button" class="btn btn-info btn-sm" @click="handleSendPasswordResetEmailClick">Send Password Reset Email</div>
                    <div class="btn btn-sm btn-outline-primary"
                    style="margin-left: 8px"
                    @click="handleViewLogs">View Logs</div>
                  </div>
                </td>
              </tr>
            </tbody>

            <thead style="border-top: 0 !important;">
            <tr>
              <th style="padding-top: 20px">MFA Phone Number</th>
              <th>Enabled</th>
              <th />
            </tr>
            </thead>
            <tbody>
            <tr>
              <td id="mfa-phone-number">{{ mfaPhoneNumberForUser && userIsEnrolledInMfa ?  mfaPhoneNumberForUser : 'pending' }}</td>
              <td id="mfa-enabled">{{ userMfaEnabled ? 'yes' : 'no' }}</td>
              <td>
                <div id="reset-mfa-button"
                    class="btn btn-info btn-sm"
                    :class="{ disabled: !canResetMfa }"
                    @click="handleResetMfaClick">Reset</div>
                <div id="toggle-mfa-button"
                    class="btn btn-sm btn-outline-primary"
                    :class="{ disabled: mfaActionInProgress }"
                    style="margin-left: 8px"
                    @click="handleToggleMfaClick">{{ userMfaEnabled ? 'Disable' : 'Enable' }}</div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import manifest from "../../manifest";
import SlotCopy from '@/components/v2/common/Generics/SlotCopy';

export default {
  name: 'UserListItem',
  props: ['user'],
    components: {
      SlotCopy
    },
  data() {
    return {
      mfaActionInProgress: false,
      userLogsModalOpen: false,
    }
  },
  methods: {
    handleSendPasswordResetEmailClick() {
      this.$store.dispatch('accounts/sendPasswordResetEmail', { user: this.user });
    },
    handleResetMfaClick() {
      if (!this.canResetMfa) {
        return;
      }

      // Set a flag so that the user can't initiate multiple MFA actions at once
      this.mfaActionInProgress = true;

      const userTrackingInfo = {
        buttonText: 'Reset',
        buttonType: 'action',
        buttonName: 'reset_mfa',
        screenName: 'user_management',
        component: 'account_page',
        pageType: 'account_page'
      };
      this.$store.dispatch('accounts/resetUserMfa', {userUuid: this.user.user_uuid, userTrackingInfo})
        .then(() => {
          this.$emit('userMfaChanged');
        })
        .then(() => {
          this.mfaActionInProgress = false;
        });
    },
    handleToggleMfaClick() {
      if (this.mfaActionInProgress) {
        return;
      }

      // Set a flag so that the user can't initiate multiple MFA actions at once
      this.mfaActionInProgress = true;

      const userTrackingInfo = {
        buttonText: this.userMfaEnabled ? 'Disable' : 'Enable',
        buttonType: 'action',
        buttonName: this.userMfaEnabled ? 'disable_mfa' : 'enable_mfa',
        screenName: 'user_management',
        component: 'account_page',
        pageType: 'account_page'
      };
      this.$store.dispatch('accounts/toggleUserMfa',
        {userUuid: this.user.user_uuid, enabled: !this.userMfaEnabled, userTrackingInfo})
        .then(() => {
          this.$emit('userMfaChanged');
        })
        .then(() => {
          this.mfaActionInProgress = false;
        });
    },
    handleViewLogs(){
      this.$store.dispatch('accounts/fetchAuth0UserLogs',{
        uuid:this.user.user_uuid,
        page:0
      })
      .then(() => {
          this.$emit('showUserLogs');
      })
    }
  },
  computed: {
    hasAuth0Mapping() {
      return this.user.auth0_id !== null;
    },
    auth0url() {
      return `${manifest.clients.auth0.host}/users/${this.user.auth0_id}`;
    },
    mfaPhoneNumberForUser() {
      if (this?.user?.mfaDetails?.exists && this.user.mfaDetails.phoneNumber) {
        return this.user.mfaDetails.phoneNumber;
      } else {
        return undefined;
      }
    },
    userIsEnrolledInMfa() {
      return this?.user?.mfaDetails?.exists && this.user.mfaDetails.status === 'confirmed';
    },
    userMfaEnabled() {
      return this.user && this.user.mfaStatus;
    },
    canResetMfa() {
      return this.mfaPhoneNumberForUser && this.userIsEnrolledInMfa && !this.mfaActionInProgress;
    },
    socialLoginInfo() {
      const socialAuthId = this.user.auth0_id;
      if (socialAuthId) {
        if (socialAuthId.startsWith('google')) {
          return {
            img: 'https://res.cloudinary.com/zenbusiness/q_auto,w_16,h_16/v1/shared-assets/s2/vector/google-logo.svg',
            text: 'Google'
          }
        }
        if (socialAuthId.startsWith('apple')) {
          return {
            img: 'https://res.cloudinary.com/zenbusiness/c_scale,co_rgb:212121,e_colorize:100,q_auto,w_24/v1/shared-assets/logo/Apple%20logo.svg',
            text: 'Apple'
          }
        }
      }
      return null;
    }
  }
};
</script>

<style lang="scss">
  .socialLoginIndicator {
    align-items: center;
    display: inline-flex;
    font-size: .875rem;
    padding: .25rem .5rem;

    img:not(.md-image) {
      height: 16px;
      margin-right: 8px;
    }

    * {
      margin-top: 8px;
    }
  }
</style>
